const cardSliders = document.getElementsByClassName('slider');

Array.from(cardSliders).forEach((cardSlider) => {
  // container elements
  const slides = cardSlider.querySelector('.slides');
  const cards = slides.querySelectorAll('.card-featured');

  // card width
  const cardWidth = cards[0].clientWidth;
  // arrows and indicators
  const arrowLeft = cardSlider.querySelector('.slider-arrow-prev');
  const arrowRight = cardSlider.querySelector('.slider-arrow-next');
  const indicators = cardSlider.querySelectorAll('.indicator');

  let activeCardNum = 1;

  if (arrowLeft) {
    // Scroll Left
    arrowLeft.addEventListener('click', () => {
      if (activeCardNum > 1 && !arrowLeft.disabled) {
        // if expanded card
        const openSlide = slides.querySelector('.open');
        const extraSpace = openSlide ? openSlide.clientWidth - cardWidth : 0;
        slides.scrollLeft -= cardWidth + extraSpace;
      }
    });
  }

  if (arrowRight) {
    // Scroll Right
    arrowRight.addEventListener('click', () => {
      if (activeCardNum < cards.length && !arrowRight.disabled) {
        // if expanded card
        const openSlide = slides.querySelector('.open');
        const extraSpace = openSlide ? openSlide.clientWidth - cardWidth : 0;
        slides.scrollLeft += cardWidth + extraSpace;
      }
    });
  }

  cards.forEach((card) => {
    card.addEventListener('focus', () => {
      const active = Number(card.dataset.slide);
      slides.scrollLeft = (active - 1) * cardWidth;
    });
  });

  if (indicators) {
    // Click on Indicator
    indicators.forEach((indicator) => {
      indicator.addEventListener('click', () => {
        const clicked = Number(indicator.dataset.slide);
        // scroll to new position
        slides.scrollLeft = (clicked - 1) * cardWidth;
      });
    });

    const sliderObserverCallback = (entries) => {
      // hides arrows if div is not overflowing
      // const arrowDiv = cardSlider.querySelector('.arrows');

      // Needs more specific logic on when to show arrows.
      // if (arrowDiv) {
      //   const arrowDivWidth = arrowDiv.parentElement.clientWidth;
      //   if (arrowDivWidth >= (cardWidth * cards.length)) {
      //     arrowDiv.classList.add('md:hidden');
      //   } else {
      //     arrowDiv.classList.remove('md:hidden');
      //   }
      // }

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          activeCardNum = Number(entry.target.dataset.slide);

          //
          // Mobile Indicator
          //

          // removes all active indicators
          for (let k = 0; k < indicators.length; k++) {
            indicators[k].classList.remove('active');
          }
          // adds active indicator
          indicators[activeCardNum - 1].classList.add('active');

          //
          // Desktop Indicator (arrows)
          //
          if (arrowLeft && arrowRight) {
            arrowLeft.disabled = false;
            arrowRight.disabled = false;

            // disable left arrow if at start
            if (activeCardNum === 1) {
              arrowLeft.disabled = true;
            }

            // disable right arrow if we are at the end
            if (activeCardNum === cards.length) {
              arrowRight.disabled = true;
            }
          }
        }
      });
    };

    const options = {
      root: cardSlider,
      rootMargin: '0px',
      threshold: 0.9,
    };

    const observer = new IntersectionObserver(sliderObserverCallback, options);
    cards.forEach((card) => {
      observer.observe(card);
    });

    // Reset to first indicator on resize
    let timeout = false;
    const resetCards = () => {
      if (!indicators[0].classList.contains('active')) {
        indicators[0].click();
      }
    };
    window.addEventListener('resize', () => {
      clearTimeout(timeout);
      timeout = setTimeout(resetCards, 250);
    });
  }
});
